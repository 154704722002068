import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "../../atoms/Box";
import { ContactLink } from "../../atoms/ContactLink";
import { CookiesLink } from "../../atoms/CookiesLink";
import { PrivacyPolicyLink } from "../../atoms/PrivacyPolicyLink";
import { HireeAppLink } from "../../atoms/HireeAppLink";
import { useMobile } from "../../../hooks/useMobile";
import { DownloadMobileApp } from "../DownloadMobileApp";
import { Colors, theme } from "../../theme";

const useStyles = makeStyles({
	root: {
		"& > *": {
			marginRight: "1.25rem",
			"&:last-child": {
				marginRight: 0,
			},
		},
	},
	footerContainer: {
		background: ({ showDownloadMobiles }: { showDownloadMobiles: boolean }) =>
			showDownloadMobiles ? Colors.DownloadMobilesBackground : "none",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "1rem",
		},
		[theme.breakpoints.up("sm")]: {
			paddingBottom: "3.25rem",
		},
	},
	downloadMobilesContainer: {
		background: Colors.DownloadMobilesBackground,
	},
});

interface FooterProps {
	showDownloadMobiles?: boolean;
}

const Footer = (props: FooterProps) => {
	const { showDownloadMobiles } = props;
	const styles = useStyles({ showDownloadMobiles });
	const { isMobile } = useMobile();

	return (
		<footer className={styles.footerContainer}>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
			>
				{showDownloadMobiles ? (
					<>
						<Box marginTop={5} marginBottom={isMobile ? 5 : 7}>
							<DownloadMobileApp />
						</Box>
						{isMobile ? null : (
							<Box display={"flex"} className={styles.root}>
								<HireeAppLink />
								<ContactLink />
								<CookiesLink />
								<PrivacyPolicyLink />
							</Box>
						)}
					</>
				) : (
					<>
						<Box display={"flex"} className={styles.root}>
							{isMobile ? null : <HireeAppLink />}
							<ContactLink />
							<CookiesLink />
							<PrivacyPolicyLink />
						</Box>
						{isMobile ? (
							<Box marginTop={2.5}>
								<HireeAppLink />
							</Box>
						) : null}
					</>
				)}
			</Box>
		</footer>
	);
};

export { Footer };
