import { useMediaQuery, useTheme } from "@material-ui/core";
import { useSize } from "./useSize";

const useMobile = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
	const { width, height } = useSize();

	return { isMobile, aspectRatio: width / height };
};

export { useMobile };
