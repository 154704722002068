import React, { ReactElement, useState } from "react";
import MUIDrawer from "@material-ui/core/Drawer";
import { HamburgerMenuIcon } from "../../icons/HamburgerMenuIcon";
import { Button } from "../Button";
import { Box } from "../Box";
import { CloseIcon } from "../../icons/CloseIcon";
import { makeStyles } from "@material-ui/core/styles";

interface DrawerProps {
	closeIcon?: ReactElement;
	openIcon?: ReactElement;
	children: ReactElement | ReactElement[];
}

const useStyles = makeStyles({
	paper: {
		boxShadow: "0 2px 15px 0 rgba(61, 64, 91, 0.08)",
	},
});

const Drawer = (props: DrawerProps) => {
	const { closeIcon, openIcon, children } = props;
	const [isOpen, setIsOpen] = useState(false);
	const styles = useStyles();

	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);

	return (
		<>
			<Button variant={"icon"} onClick={open}>
				{openIcon || <HamburgerMenuIcon />}
			</Button>
			<MUIDrawer
				anchor={"top"}
				open={isOpen}
				onClose={close}
				classes={{ paper: styles.paper }}
			>
				<Box display={"flex"} justifyContent={"end"}>
					<Button variant={"icon"} onClick={close}>
						{closeIcon || <CloseIcon />}
					</Button>
				</Box>
				{children}
			</MUIDrawer>
		</>
	);
};

export { Drawer };
