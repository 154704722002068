import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";
import { TColors, theme } from "../../theme";

const useStyles = makeStyles({
	root: {
		[theme.breakpoints.down("sm")]: {
			width: "1.75rem",
		},
		[theme.breakpoints.up("sm")]: {
			width: "2rem",
		},
		height: "100%",
	},
});

export type SocialIconSize = "small" | "medium";

interface SocialIconProps {
	color?: TColors;
	children: ReactElement;
	viewBox?: string;
}

const SocialIcon = (props: SocialIconProps) => {
	const { children, color, viewBox } = props;
	const styles = useStyles();

	return (
		<SvgIcon viewBox={viewBox || "0 0 32 32"} className={styles.root} htmlColor={color}>
			{children}
		</SvgIcon>
	);
};

export { SocialIcon };
