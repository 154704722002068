import React from "react";
import { makeStyles } from "@material-ui/core";
import { Box } from "../../atoms/Box";
import Link from "../../atoms/Link";
import { Typography } from "../../atoms/Typography";
import { FacebookIcon } from "../../icons/FacebookIcon";
import { LinkedinIcon } from "../../icons/LinkedinIcon";
import { InstagramIcon } from "../../icons/InstagramIcon";
import { Colors } from "../../theme";
import { StartQuizButton } from "../../atoms/StartQuizButton";

interface DesktopMenuProps {
	isResultPage?: boolean;
}

const useStyles = makeStyles({
	text: {
		fontWeight: 500,
	},
});

const DesktopMenu = (props: DesktopMenuProps) => {
	const { isResultPage } = props;
	const styles = useStyles();

	return (
		<Box
			display={"flex"}
			alignItems={"center"}
			justifyContent={"flex-end"}
			width={"100%"}
		>
			<Box marginRight={3}>
				<Link href={process.env.BLOG_URL} color={"inherit"} underline={"hover"}>
					<Typography className={styles.text} variant={"body2"}>
						Blog
					</Typography>
				</Link>
			</Box>
			<Box marginRight={3}>
				<Link href={process.env.HIREE_URL} color={"inherit"} underline={"hover"}>
					<Typography className={styles.text} variant={"body2"}>
						Poznaj hiree
					</Typography>
				</Link>
			</Box>
			<Box display={"flex"} justifyContent={"center"}>
				<Link href={process.env.FACEBOOK_URL} color={"inherit"} underline={"none"}>
					<FacebookIcon color={Colors.LightText} />
				</Link>
				<Box paddingX={1.5}>
					<Link href={process.env.LINKEDIN_URL} color={"inherit"} underline={"none"}>
						<LinkedinIcon color={Colors.LightText} />
					</Link>
				</Box>
				<Link href={process.env.INSTAGRAM_URL} color={"inherit"} underline={"none"}>
					<InstagramIcon color={Colors.LightText} />
				</Link>
				{isResultPage ? (
					<Box marginLeft={3}>
						<StartQuizButton variant={"nav"} />
					</Box>
				) : null}
			</Box>
		</Box>
	);
};

export { DesktopMenu };
