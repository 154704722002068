import React, { ReactNode } from "react";
import { navigate } from "gatsby";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { Button } from "../Button";
import { Colors, theme } from "../../theme";

interface StartQuizButtonProps {
	variant?: "nav" | "default";
	children?: ReactNode;
}

const useStyles = makeStyles({
	root: {
		paddingTop: "1.125rem",
		paddingBottom: "1.125rem",
		maxWidth: "14.375rem",
		width: "100%",
	},
	navButton: {
		fontSize: "0.75rem",
		letterSpacing: "1.5px",
		color: Colors.White,
		fontWeight: 600,
	},
	button: {
		[theme.breakpoints.down("md")]: {
			fontSize: "0.75rem",
			letterSpacing: "2.25px",
		},
		[theme.breakpoints.up("md")]: {
			fontSize: "1rem",
			letterSpacing: "3px",
		},
	},
});

const startQuiz = () => {
	navigate(`/1`);
};

const StartQuizButton = (props: StartQuizButtonProps) => {
	const { variant, children } = props;
	const styles = useStyles();

	if (variant === "nav") {
		return (
			<Button className={styles.navButton} variant={"contained"} onClick={startQuiz}>
				{children || "ZRÓB TEST"}
			</Button>
		);
	}

	return (
		<Button
			className={clsx(styles.root, styles.button)}
			variant={"contained"}
			fullWidth
			onClick={startQuiz}
		>
			{children || "Start"}
		</Button>
	);
};

export { StartQuizButton };
