import React from "react";
import Link from "../Link";
import { Typography } from "../Typography";

interface PrivacyPolicyLinkProps {
	url?: string;
}

const PrivacyPolicyLink = (props: PrivacyPolicyLinkProps) => {
	const url = props.url || process.env.PRIVACY_POLICY_URL;

	return (
		<Typography variant={"caption"}>
			<Link href={url} color={"inherit"} underline={"always"}>
				Polityka Prywatności
			</Link>
		</Typography>
	);
};

export { PrivacyPolicyLink };
