import React from "react";
import { Colors, TColors } from "../../theme";
import { SocialIcon } from "../SocialIcon";

interface FacebookIconProps {
	color?: TColors;
}

const FacebookIcon = (props: FacebookIconProps) => {
	const { color } = props;
	return (
		<SocialIcon color={color || Colors.Text}>
			<path d='M27 0a5 5 0 0 1 5 5v22a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h22zm-1.488 5h-4c-3.675 0-6 2.881-6 7v3h-4v5h4v12h5V20h4l1-5h-5v-2c0-2 1.002-3 3-3h2V5z' />
		</SocialIcon>
	);
};

export { FacebookIcon };
