import { useEffect, useState } from "react";

function useSize() {
	const isBrowser = typeof window !== "undefined";
	const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
	const [height, setHeight] = useState(isBrowser ? window.innerHeight : 0);

	function handleResize() {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}

	useEffect(() => {
		if (!isBrowser) {
			return;
		}

		window.addEventListener("resize", handleResize);

		return function cleanUp() {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return { width, height };
}

export { useSize };
