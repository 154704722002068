import React from "react";
import { Colors, TColors } from "../../theme";
import { SocialIcon } from "../SocialIcon";

interface LinkedinIconProps {
	color?: TColors;
}

const LinkedinIcon = (props: LinkedinIconProps) => {
	const { color } = props;
	return (
		<SocialIcon viewBox={"0 0 24 24"} color={color || Colors.Text}>
			<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M18.611.389a5 5 0 0 1 5 5V18.61a5 5 0 0 1-5 5H5.39a5 5 0 0 1-5-5V5.39a5 5 0 0 1 5-5H18.61zM8.543 9.604H5.828v8.167h2.715V9.604zm6.664-.192c-1.442 0-2.087.794-2.447 1.35V9.603h-2.715c.009.196.013.826.015 1.655v2.307l-.002.544c-.004 1.534-.01 2.983-.013 3.48v.18h2.715v-4.56c0-.245.018-.488.09-.662.196-.489.643-.993 1.393-.993.983 0 1.376.749 1.376 1.846v4.37h2.714v-4.684c0-2.508-1.34-3.675-3.126-3.675zM7.203 5.667c-.928 0-1.536.61-1.536 1.41 0 .785.59 1.412 1.5 1.412h.019c.946 0 1.536-.627 1.536-1.412-.018-.8-.59-1.41-1.519-1.41z'
					fillRule='evenodd'
				/>
			</svg>
		</SocialIcon>
	);
};

export { LinkedinIcon };
