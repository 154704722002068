import React from "react";
import Link from "../Link";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "../Typography";
import { theme } from "../../theme";

const useStyles = makeStyles({
	root: {
		[theme.breakpoints.up("sm")]: {
			opacity: "100%",
		},
	},
});

interface HireeAppLinkProps {
	url?: string;
}

const HireeAppLink = (props: HireeAppLinkProps) => {
	const styles = useStyles();
	const url = props.url || process.env.HIREE_URL;

	return (
		<Typography className={styles.root} variant={"caption"}>
			<Link href={url} color={"inherit"}>
				© hiree.app
			</Link>
		</Typography>
	);
};

export { HireeAppLink };
