import React from "react";
import Link from "../Link";
import { Typography } from "../Typography";

interface ContactLinkProps {
	url?: string;
}

const CookiesLink = (props: ContactLinkProps) => {
	const url = props.url || process.env.COOKIES_URL;

	return (
		<Typography variant={"caption"}>
			<Link href={url} color={"inherit"} underline={"always"}>
				Polityka Cookies
			</Link>
		</Typography>
	);
};

export { CookiesLink };
