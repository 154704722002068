import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";
import { Colors } from "../../theme";

const useStyles = makeStyles({
	root: {
		width: "2.5rem",
		height: "100%",
	},
});

const CloseIcon = () => {
	const styles = useStyles();
	return (
		<SvgIcon className={styles.root} color={"secondary"} viewBox={"0 0 40 40"}>
			<g stroke={Colors.Text} strokeLinecap='round' strokeWidth='2'>
				<path d='m12.354 12.353 16.263 16.264M28.617 12.353 12.354 28.617' />
			</g>
		</SvgIcon>
	);
};

export { CloseIcon };
