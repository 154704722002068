import React from "react";
import { Box } from "../../atoms/Box";
import { Typography } from "../../atoms/Typography";
import Link from "../../atoms/Link";
import { AppStoreIcon } from "../../icons/AppStoreIcon";
import { GooglePlayIcon } from "../../icons/GooglePlayIcon";

const DownloadMobileApp = () => {
	return (
		<Box>
			<Box
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
			>
				<Typography variant={"body1"}>Pobierz hiree za darmo</Typography>
				<Box display={"flex"} marginTop={2.5}>
					<Box marginRight={0.5}>
						<Link href={process.env.APPLE_STORE_URL} color={"inherit"} underline={"none"}>
							<AppStoreIcon />
						</Link>
					</Box>
					<Box marginLeft={0.5}>
						<Link href={process.env.GOOGLE_PLAY_URL} color={"inherit"} underline={"none"}>
							<GooglePlayIcon />
						</Link>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export { DownloadMobileApp };
