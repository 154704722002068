import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";
import { theme } from "../../theme";

const useStyles = makeStyles({
	root: {
		[theme.breakpoints.down("md")]: {
			width: "3.8125rem",
		},
		[theme.breakpoints.up("md")]: {
			width: "5rem",
		},
		height: "100%",
	},
});

const HireeLogoIcon = () => {
	const styles = useStyles();

	return (
		<SvgIcon className={styles.root} viewBox={"0 0 61 34"} color={"secondary"}>
			<g>
				<path d='M8.253 19.245c-2.195 0-3.863 1.074-4.724 2.12v-5.37c0-.99-.778-1.809-1.75-1.809-.973 0-1.779.82-1.779 1.809v15.629c0 .989.806 1.808 1.778 1.808.973 0 1.751-.82 1.751-1.808v-7.575c.611-.82 1.695-1.61 3.057-1.61 1.528 0 2.5.593 2.5 2.487v6.698c0 .989.806 1.808 1.751 1.808 1 0 1.779-.82 1.779-1.808V23.54c0-2.657-1.418-4.296-4.363-4.296zm9.781.141c-1 0-1.778.82-1.778 1.81v10.428c0 .989.778 1.808 1.75 1.808.973 0 1.78-.82 1.78-1.808V21.195c0-.99-.807-1.809-1.752-1.809zm32.935 5.738c.166-1.357 1.111-3.025 3.306-3.025 2.334 0 3.224 1.724 3.307 3.025h-6.613zM61 25.858c0-3.56-2.723-6.613-6.725-6.613-4.085 0-6.947 3.194-6.947 7.15 0 4.38 3.085 7.179 7.17 7.179 1.556 0 3.112-.368 4.418-1.13.417-.255.75-.707.75-1.244 0-.763-.639-1.413-1.39-1.413-.25 0-.472.056-.666.17-.834.48-1.834.762-2.723.762-2.279 0-3.64-1.441-3.863-3.165h7.864c1.362 0 2.112-.509 2.112-1.696zm-24.995-.734c.166-1.357 1.111-3.025 3.307-3.025 2.334 0 3.223 1.724 3.306 3.025h-6.613zm3.307-5.879c-4.085 0-6.947 3.194-6.947 7.15 0 4.38 3.084 7.179 7.169 7.179 1.556 0 3.112-.368 4.418-1.13.417-.255.75-.707.75-1.244 0-.763-.639-1.413-1.389-1.413-.25 0-.472.056-.667.17-.834.48-1.834.762-2.723.762-2.279 0-3.64-1.441-3.863-3.165h7.864c1.362 0 2.112-.509 2.112-1.696 0-3.56-2.723-6.613-6.724-6.613zm-8.662.085c-.305 0-.555.084-.889.226-.806.31-1.612 1.074-2.195 1.893l.028-.254a1.794 1.794 0 0 0-1.807-1.809c-.972 0-1.75.82-1.75 1.837v10.4c0 .99.778 1.81 1.778 1.81.945 0 1.75-.82 1.75-1.81V24.22c.584-.876 1.974-1.385 2.89-1.498 1.112-.17 1.668-.904 1.668-1.78s-.528-1.611-1.473-1.611zM17.732 0a9.22 9.22 0 1 0 0 18.442 9.221 9.221 0 1 0 0-18.442m0 2.071c3.943 0 7.15 3.208 7.15 7.15s-3.207 7.15-7.15 7.15c-3.942 0-7.15-3.208-7.15-7.15s3.208-7.15 7.15-7.15' />
				<path d='M22.11 9.457c-.03 0-.06 0-.091-.003-.578-.046-1.005-.511-.956-1.04.12-1.285-.865-2.481-2.243-2.725-.57-.1-.942-.604-.832-1.126.11-.522.66-.863 1.228-.762 2.419.427 4.148 2.525 3.937 4.777-.047.501-.505.88-1.043.88' />
			</g>
		</SvgIcon>
	);
};

export { HireeLogoIcon };
