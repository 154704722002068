import { List, ListItem } from "../../atoms/List";
import Link from "../../atoms/Link";
import { Box } from "../../atoms/Box";
import { Typography } from "../../atoms/Typography";
import { Divider } from "../../atoms/Divider";
import { FacebookIcon } from "../../icons/FacebookIcon";
import { LinkedinIcon } from "../../icons/LinkedinIcon";
import { InstagramIcon } from "../../icons/InstagramIcon";
import React from "react";

const MobileMenu = () => {
	return (
		<List>
			<Link href={process.env.BLOG_URL} color={"inherit"} underline={"none"}>
				<ListItem button alignItems={"center"}>
					<Box width={"100%"} display={"flex"} justifyContent={"center"}>
						<Typography variant={"h3"}>Blog</Typography>
					</Box>
				</ListItem>
			</Link>
			<Box paddingX={"30%"} paddingY={1.5}>
				<Divider />
			</Box>
			<Link href={process.env.HIREE_URL} color={"inherit"} underline={"none"}>
				<ListItem button alignItems={"center"}>
					<Box width={"100%"} display={"flex"} justifyContent={"center"}>
						<Typography variant={"h3"}>Poznaj hiree</Typography>
					</Box>
				</ListItem>
			</Link>
			<Box paddingX={"30%"} paddingY={1.5}>
				<Divider />
			</Box>
			<ListItem alignItems={"center"}>
				<Box width={"100%"} display={"flex"} justifyContent={"center"}>
					<Link href={process.env.FACEBOOK_URL} color={"inherit"} underline={"none"}>
						<FacebookIcon />
					</Link>
					<Box paddingX={1}>
						<Link href={process.env.LINKEDIN_URL} color={"inherit"} underline={"none"}>
							<LinkedinIcon />
						</Link>
					</Box>
					<Link href={process.env.INSTAGRAM_URL} color={"inherit"} underline={"none"}>
						<InstagramIcon />
					</Link>
				</Box>
			</ListItem>
		</List>
	);
};

export { MobileMenu };
