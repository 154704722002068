import React, { ReactElement, ReactNode } from "react";
import clsx from "clsx";
import MUIButton from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";

interface ButtonBaseProps {
	onClick: () => void;
	endIcon?: ReactElement;
	startIcon?: ReactElement;
	fullWidth?: boolean;
	className?: string;
}

type ButtonVariantsProps =
	| {
			variant: "text" | "contained";
			children: ReactNode;
	  }
	| {
			variant: "icon";
			children: ReactNode;
	  };

type ButtonProps = ButtonBaseProps & ButtonVariantsProps;

const useStyles = makeStyles({
	root: {
		borderRadius: "2rem",
	},
});

const Button = (props: ButtonProps) => {
	const { variant, children, onClick, endIcon, startIcon, fullWidth, className } = props;
	const styles = useStyles();

	if (variant === "contained") {
		return (
			<MUIButton
				variant={variant}
				color={"primary"}
				disableElevation
				onClick={onClick}
				endIcon={endIcon}
				startIcon={startIcon}
				fullWidth={fullWidth}
				className={clsx(styles.root, className)}
			>
				{children}
			</MUIButton>
		);
	}

	if (variant === "icon") {
		return (
			<IconButton className={className} onClick={onClick}>
				{children}
			</IconButton>
		);
	}

	if (variant === "text") {
		return (
			<MUIButton
				className={clsx(styles.root, className)}
				color='primary'
				onClick={onClick}
			>
				{children}
			</MUIButton>
		);
	}

	const exhaustiveCheck: never = variant;

	throw new Error(`Unsupported Button variant: ${variant}`);
};

export { Button };
