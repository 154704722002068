import React from "react";
import { makeStyles } from "@material-ui/core";
import { useLocation } from "@reach/router";
import { Box } from "../../atoms/Box";
import { HireeLogoIcon } from "../../icons/HireeLogoIcon";
import { Drawer } from "../../atoms/Drawer";
import { MobileMenu } from "../MobileMenu";
import { DesktopMenu } from "../DesktopMenu";
import { StartQuizButton } from "../../atoms/StartQuizButton";
import { useMobile } from "../../../hooks/useMobile";
import { Link } from "gatsby";

const MobileNavigation = () => (
	<Box marginRight={1.5}>
		<Drawer>
			<MobileMenu />
		</Drawer>
	</Box>
);

const useStyles = makeStyles({
	root: {
		background: "inherit",
		boxShadow: ({ isMobile }: { isMobile: boolean }) =>
			isMobile ? "0 2px 15px 0 rgba(61, 64, 91, 0.08)" : "none",
		marginBottom: ({ isMobile }: { isMobile: boolean }) => (isMobile ? "1rem" : ""),
		border: ({ isMobile }: { isMobile: boolean }) =>
			isMobile ? "solid 1px #e8e8ea" : "none",
	},
});

const NavigationBar = () => {
	const { isMobile } = useMobile();
	const styles = useStyles({ isMobile });
	const location = useLocation();
	const isResultPage = location.pathname.includes("result");

	return (
		<nav className={styles.root}>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				paddingLeft={isMobile ? 3.25 : 5.25}
				paddingTop={isMobile ? 0 : 3}
				paddingRight={isMobile ? 0 : 5.25}
			>
				<Link to={"/"}>
					<HireeLogoIcon />
				</Link>
				{isMobile && isResultPage ? <StartQuizButton variant={"nav"} /> : null}
				{isMobile ? <MobileNavigation /> : <DesktopMenu isResultPage={isResultPage} />}
			</Box>
		</nav>
	);
};

export { NavigationBar };
