import React from "react";
import MUIList, { ListProps } from "@material-ui/core/List";
import MUIListItem, { ListItemProps } from "@material-ui/core/ListItem";
import MUIListItemText, { ListItemTextProps } from "@material-ui/core/ListItemText";
import MUIListItemIcon, { ListItemIconProps } from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme";

const useStyles = makeStyles({
	listItem: {
		"&:hover": {
			backgroundColor: Colors.LightHover,
		},
	},
});

const List = (props: ListProps) => {
	return <MUIList {...props} />;
};

const ListItem = (props: ListItemProps) => {
	const styles = useStyles();

	return <MUIListItem className={styles.listItem} {...(props as unknown)} />;
};

const ListItemText = (props: ListItemTextProps) => {
	return <MUIListItemText {...props} />;
};

const ListItemIcon = (props: ListItemIconProps) => {
	return <MUIListItemIcon {...props} />;
};

export { List, ListItem, ListItemText, ListItemIcon };
