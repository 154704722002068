import React, { ReactNode } from "react";
import MUITypography, { TypographyProps } from "@material-ui/core/Typography";

const TypographyVariants = ["h1", "h2", "h3", "h4", "body1", "body2", "caption"] as const;

type CustomTypographyProps = {
	variant: typeof TypographyVariants[number];
	component?: typeof TypographyVariants[number];
	children: ReactNode | ReactNode[];
	className?: string;
} & Pick<TypographyProps, "color" | "align">;

const Typography = (props: CustomTypographyProps) => {
	return <MUITypography {...props} />;
};

export { Typography, TypographyVariants };
