import MUILink from "@material-ui/core/Link";
import { LinkProps } from "@material-ui/core/Link/Link";

import React from "react";

const Link = (props: LinkProps) => {
	return <MUILink {...props} />;
};

export default Link;
