import React from "react";
import Link from "../Link";
import { Typography } from "../Typography";

interface ContactLinkProps {
	mail?: string;
}

const ContactLink = (props: ContactLinkProps) => {
	const mail = props.mail || process.env.CONTACT_EMAIL;

	return (
		<Typography variant={"caption"}>
			<Link href={`mailto: ${mail}`} color={"inherit"} underline={"always"}>
				{mail}
			</Link>
		</Typography>
	);
};

export { ContactLink };
