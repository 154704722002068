import React, { ReactElement } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { NavigationBar } from "../../molecules/NavigationBar";
import { Footer } from "../../molecules/Footer";
import { Box } from "../../atoms/Box";

interface LayoutProps {
	children: ReactElement;
	className?: string;
	isResultPage?: boolean;
	showDownloadMobiles?: boolean;
}

const useStyles = makeStyles({
	root: {
		height: "100%",
		maxWidth: "100vw",
		position: "relative",
	},
	background: {
		background: "inherit",
	},
});

const Layout = (props: LayoutProps) => {
	const styles = useStyles();
	const { children, className, showDownloadMobiles } = props;
	const isClient = typeof window !== "undefined";

	// To prevent rendering when MUI styles are not ready yet.
	if (!isClient) {
		return null;
	}

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"space-between"}
			className={clsx(styles.root, className)}
		>
			<Box className={styles.background}>
				<NavigationBar />
				{children}
			</Box>
			<Box className={clsx(styles.background)}>
				<Footer showDownloadMobiles={showDownloadMobiles} />
			</Box>
		</Box>
	);
};

export { Layout };
